.item {
    border-width: 1px;
    border-style: solid;
    width: auto;
    margin-bottom: 2rem;
}

.item_title {
    margin: 1rem;
    font-size: 22px;
    font-weight: bolder;
}

.item_date {
    font-size: smaller;
    font-weight: normal;
}

.item a {
    color: var(--background-color);
}


@media (max-width: 50em) {
    .item {
        width: 100%;
        font-size: 35px;
        margin-bottom: 2rem;
    }

    .item_title {
        font-size: 20px;
    }
}




@media  (min-width: 1281px) and (max-width: 1600px) {
    .item {
        width: 100%;
        font-size: 30px;
        margin-bottom: 2rem;
    }

    .item_title {
        font-size: 20px;
    }
}

@media (min-height:2160px) {
    .item_title {
        font-size: 2.4rem;
    }
}
