@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Vollkorn:ital,wght@0,500;1,400;1,500&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,600;0,700;1,400;1,500&display=swap');
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
    padding: 20px;
    text-align: center;
}

@font-face {
    font-family: Neue;
    src: url(./fonts/neu/NeueHaasDisplayMediu.ttf);
}

mark {
    padding: var(--inside-padding);
    margin: 0;
    box-sizing: border-box;
}

h1, h2, h3 {
    font-family: 'Neue', sans-serif;
        padding: var(--inside-padding) var(--inside-padding);
    
}


@media (orientation: landscape) {
    ::-webkit-scrollbar {
        width: 10px;
        background-color: var(--color-background);
    }
}

@media (orientation: landscape) {
    ::-webkit-scrollbar-thumb {
        background-color: var(--color-text);
    }
}

@media (orientation: landscape) {
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
}
