.nameContainer {
  display: flex;
  font-weight: bold;
  font-family: 'Neue', sans-serif;
}

.name {
  align-self: center;
  font-size: 2.5rem;
}

.about {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #727171;
  padding-bottom: 1rem;
}


.links {
  display: flex;
  justify-content: flex-end;
  font-family: 'Neue', sans-serif !important;
}

.links a {
  margin-right: 1rem;
  padding: var(--inside-padding);
  color: var(--color-background);
  font-size: 1.3rem;
  font-weight: bold;
}

.links input {
  margin-left: 3rem;
}

.github_link {
  background-color: #4078c0;
}

.mail {
  background-color: #c50234;
}

.spotify {
  background-color: #78BC61;
}

.cv {
  background-color: #9b3cd9;
}

.darkToggle {
  //https://codepen.io/mrozilla/pen/OJJNjRb
  --size: 2.3rem;

  appearance: none;
  outline: none;
  cursor: pointer;

  width: var(--size);
  height: var(--size);
  box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
  border-radius: 999px;
  color: hsl(0, 0%, 23%);

  transition: all 500ms;

  &:checked {
    --ray-size: calc(var(--size) * -0.38);
    --offset-orthogonal: calc(var(--size) * 0.68);
    --offset-diagonal: calc(var(--size) * 0.50);

    transform: scale(0.75);
    color: hsl(0deg, 0%, 85%);
    box-shadow: inset 0 0 0 var(--size),
    calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
    var(--offset-orthogonal) 0 0 var(--ray-size),
    0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
    0 var(--offset-orthogonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size),
    var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
    var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size),;
  }
}


@media (max-width: 50em) {
  .nameContainer {
    margin: 5vw 0 5vw 2vw;
  }

  .links {
    margin-top: 25px;
    display: inline-grid;
    justify-content: space-between;
  }

  .links a {
    margin-bottom: .5rem;
    padding: var(--inside-padding);
    color: var(--color-background);
    font-size: 1rem;
    font-weight: bold;
  }

  .links input {
    margin-left: 1rem;
    margin-top: .9rem;
  }

}
