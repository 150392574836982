

.wtitle {
    font-size: 2rem;
    text-align: left;
    color: var(--color-background);
    background-color: var(--red);
    height: 2.25rem;
}


.list {
    width: 30%;
}

.list h2 {
    margin: 2rem 0 2rem 0;
}

.listMembrane {
    display: flex;
    position: relative;
    z-index: 2;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    padding-top: 2rem;
    gap: 2rem;
    margin-left: 50px;
    margin-right: 50px;
}

div .list {
    flex-grow: 1;
    flex-shrink: 1;
}
div .list:last-child{
    margin-right: auto;
}


.list h2:first-child {
    margin-bottom: 1rem;
    border-width: 1px;
}


.stitle {
    font-size: 2rem;
    text-align: left;
    margin-top: 4px;
    color: var(--color-background);
    background-color: #EAC435;
    height: 2.25rem;
    display: flex;
    flex-wrap: wrap;
}

.metitle {
    font-size: 2rem;
    text-align: left;
    margin-top: 4px;
    /*margin-left: 70rem;*/
    /*position: absolute;*/
    color: var(--color-background);
    background-color: #00AF54;
    height: 2.25rem;
}


a {
    text-decoration: none;
}

.ayemyunus {
    padding-top: 50px;
    padding-left: 50px;
    max-width: 90%;
}


@media  (min-width: 1281px) and (max-width: 1600px) {
    .listMembrane{
        gap:0.2em;
    }
}


@media (max-width:806px) {
    .item {
        width: 100%;
        font-size: 35px;
        margin-bottom: 2rem;
    }

    .listMembrane {
        display: contents;
        justify-content: center;
        /*position: relative;*/
        /*z-index: 2;*/
        padding-top: 1rem;
        width: 100%;
        margin-right: 10px;
        margin-left: 10px;
    }

    .list {
        display: inline-table;
        justify-content: space-around;
        transition: 0.3s;
        width: 100%;
    }

    .ayemyunus {
        display: none;
        padding-top: 50px;
        padding-left: 0;
        max-width: 100%;
    }

}

@media (min-width: 1025px) and (max-width: 1280px) {
    .montaigne {
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 1rem;
        position: relative;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .montaigne {
        margin-left: 0;
        margin-right: 2%;
        margin-top: 1rem;
        position: relative;
    }
}

@media (min-width: 1281px) and (max-width: 1600px) {
    .montaigne {
        margin-left: 0;
        margin-right: 2%;
        margin-top: 1rem;
        position: relative;
    }

    .list {
        margin-right: 2rem;
    }
}
@media (min-height:2160px) {
    .list h2:first-child {
        margin-bottom: 2rem;
        border-width: 2px;
    }
    .wtitle{
        font-size:4rem;
        height: 5rem
    }
    .metitle{
        font-size:4rem;
        height: 5rem
    }
    .stitle{
        font-size:4rem;
        height: 5rem
    }

}
