@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.vintage {
    font-family: 'Playfair Display', serif !important;
    font-optical-sizing: auto;
    color: #2c2c2c;
    font-size: 1.2rem;
    line-height: 1.8;
    background-color: #e6e2d6;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

.vintage * {
    font-family: 'Playfair Display', serif !important;
}


:root {
    background-color: #e6e2d6;
    color: #2c2c2c;
}
